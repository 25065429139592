<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-vorbe-grele">
        <img src="@/assets/casestudy/ciutacu-logo.svg" alt="Vorbe Grele">
        <span class="sep"></span>
        <p>Victor Ciutacu is a Romanian journalist, former editor in chief at the daily newspaper Jurnalul National and also the former host of the TV show Vorbe grele which was broadcast on Antena 3.
          He joined Romania TV in March 2013.</p>
      </div>
    </div>

    <div class="laptop-img ciutacu-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/ciutacu-main-img.png" alt="Vorbe Grele">
      </div>
    </div>

    <div class="container-about ciutacu-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/ciutacu-diamond.svg" alt="Vorbe Grele">
        <h3>About the project</h3>
        <p>One of the most well-known, respected and acid talk show host and journalist in Romania.<br/>
          Victor’s blog is where you’ll find his comments about political events or last minute news that usually are posted here first and other publications follow.<br/>
          We’re happy to have built Victor’s blog and also that we are able to keep it online when he gets 2000+ users per minute when breaking news gets posted.</p>

      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img class="doublefont" src="@/assets/casestudy/dosis-font.svg" alt="Vorbe Grele Main Font">
            <h3>
              <span style="color:#12100c">Co</span><span style="color:#dc3522">lo</span><span style="color:#c3c3c3">rs</span>
            </h3>

            <div class="colors">
              <div class="fcolor ciutacu-p">Primary</div>
              <div class="scolor ciutacu-s">Secondary</div>
              <div class="tcolor ciutacu-t">Third</div>
            </div>

            <span class="sep vertical color-tag"></span>
            <div class="color-codes">
              <div class="ccodes">
                <p class="primary-vb">#dc3522</p>
                <p class="secondary-vb">#12100c</p>
                <p class="third-vb">#c3c3c3</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Testimonial tsTitle="#dc3522">
      <div class="testimonial-text">
        <p class="testimonial-title-text">Victor Ciutacu, Talk Show Host</p>
        <p class="testimonial-text__content">Professional and realiable guys! Great to work with.</p>
      </div>
    </Testimonial>


    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'Hoinarim'}" class="left">
              <img src="@/assets/casestudy/btn-hoinarim.jpg" alt="Hoinarim">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Vonino'}" class="right">
              <img src="@/assets/casestudy/btn-vonino.jpg" alt="Vonino">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs color="#dc3522" colorTitle="#fafafa" colorButton="#fafafa"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Home/Footer";
import Testimonial from "../../components/Testimonial";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Ciutacu",
  components: {LetsChat, Testimonial, Footer, ContactUs}
}
</script>

<style lang="scss">
.logo-vorbe-grele img {
  max-width: 320px;
}

.ciutacu-laptop {
  background: #dc3522;
}

.ciutacu-s {
  background: #12100c;
}

.ciutacu-t {
  background: #c3c3c3;
}

.ciutacu-about {
  background: #f0f0f0;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #dc3522 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>